.croix-rouge-v2 {
  .numero {
    display: flex;
    min-height: 100vh;

    .main {
      min-height: 100vh;
      overflow: hidden;
    }

    .loading-main {
      position: absolute;
      top: 0;
      width: 100vw;
      height: 100vh;
      background: #ff0000bd;
    }
  }

  .componentExemple {
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: yellow;
    font-size: 5rem;
  }
  .loading-numero {
    display: flex;
    align-items: center;
    justify-content: center;

    @keyframes animateLogo {
      0% {
        opacity: 0.1;
      }
      100% {
        opacity: 0.2;
      }
    }

    .logo {
      background-image: url(../../../../ridmi.svg);
      background-repeat: no-repeat;
      width: 254px;
      height: 78px;
      background-size: contain;
      filter: grayscale(1);
      opacity: 0.12;
      animation: animateLogo 0.8s ease infinite 1s;

      @media (max-width: 425px) {
        width: 180px;
        height: 56px;
      }
    }
  }
}

@import "./numero-media.scss";
@import "./lateral-bar.scss";

@import "../components/style/header.scss";
@import "../components/style/lateral-bar.scss";
@import "../components/style/main.scss";
