@import "../../mixins.scss";

.croix-rouge-v2 {
  .my-gallery-class {
    overflow: hidden;
  }

  .component {
    &.component-carrousel {
      width: 100% !important;
      margin-top: 2rem;
      margin-bottom: 2rem;

      .slick-slide {
        //border-radius: 5px;
      }
    }

    &.component-text {
      color: black;

      @media (max-width: 768px) {
        table {
          border-collapse: collapse;
          width: 100% !important;
          display: block;
          tbody {
            width: 100% !important;
            display: block;
          }
          tr {
            width: 100% !important;
            display: inline-grid;
            grid-template-columns: 50% 50%;

            td {
              padding: 1rem;
              width: 100%;
            }
          }
        }
      }
    }

    &.component-chiffres {
      margin-top: 2rem;
      margin-bottom: 2rem;
      width: 100% !important;
      padding: 0 !important;
      position: relative;

      .slick-track {
        display: flex !important;
      }

      .slick-slide {
        height: inherit !important;
      }

      .prev-slider,
      .next-slider {
        position: absolute;
        z-index: 1;
        top: 50%;
        background: #E20613;
        color: white;
        width: 2.2rem;
        height: 2.2rem;
        font-size: 0.9rem;
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }

      .prev-slider {
        left: 0;
        transform: translate(-50%, -50%);
      }

      .next-slider {
        right: 0;
        transform: translate(50%, -50%);
      }

      &.has-icon {
        .prev-slider {
          transform: translate(-50%, 20%);
        }

        .next-slider {
          transform: translate(50%, 20%);
        }
      }

      .slick-slide {
        background: transparent;

        .content-chiffre {
          display: flex !important;
          // background-color: #2E6066;
          background: transparent;

          .inner {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            border: 2px solid #E20613;
            border-radius: 5px;
            padding-top: 2.4rem;
            padding-bottom: 2.4rem;
            padding-left: 2.1rem;
            padding-right: 2.1rem;
            width: 100%;

            &.inner-icon {
              padding-top: 0;
              margin-top: 4rem;
      
              .chiffre {
                margin-top: -2rem;
              }
            }
      
            .icon {
              display: flex;
              max-width: 8rem;
              max-height: 8rem;
              min-height: 6rem;
              align-items: center;
              transform: translate(0, -50%);
      
              img {
                object-fit: contain;
                max-width: 8rem;
                max-height: 8rem;
              }
            }

            @media (max-width: 425px) {
              padding-left: 2rem;
              padding-right: 2rem;
            }
          }

          .legende {
            color: black;
            width: 100%;
            text-align: center;
            font-size: 0.9rem;
            font-weight: 400;
            margin-top: 0.8rem;
          }

          .chiffre {
            color: black;
            font-weight: 600;
            font-size: 5rem;
            line-height: 3rem;
            text-align: center;

            @media (max-width: 1550px) {
              font-size: 5rem;
              line-height: 3rem;
            }

            @media (max-width: 425px) {
              font-size: 4rem;
              line-height: 2rem;
            }
          }

          > * {
            height: auto;
          }

          .inner {
            height: auto;
            box-sizing: content-box;
          }
        }
      }
    }

    .slick-track {
      display: flex;
      align-items: center;
    }

    &.component-chiffres-old {
      margin-top: 2rem;
      margin-bottom: 2rem;
      width: 100% !important;

      .slick-next:before,
      .slick-prev:before {
        content: "";
        width: 100%;
        height: 100%;
        background: black;
        position: absolute;
        border-radius: 100%;
        top: 0;
        left: 0;
      }

      .slick-slider {
        height: 206px;

        @media (max-width: 600px) {
          height: 129px;
        }

        .slick-list,
        .slick-track {
          height: 100%;
        }

        .slick-slide {
          * {
            height: 100% !important;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
      }

      .slick-slide {
        border-radius: 5px;

        &.slick-active {
          padding-left: 0.5rem;
          padding-right: 0.5rem;
          .content-chiffre {
            display: flex !important;
            background: #35b6b1;
            color: white;
            transition: all 0.6s ease;
            //border-radius: 5px;

            .chiffre {
              opacity: 1;
              transition: opacity 1s ease;
            }

            .legende {
              opacity: 1;
              transition: opacity 1s ease;
            }
          }
        }

        .content-chiffre {
          display: flex !important;
          // background-color: #2E6066;
          background: #35b6b1;
          color: white;

          .chiffre {
            opacity: 1;
            transition: opacity 1s ease;
          }

          .legende {
            opacity: 1;
            transition: opacity 1s ease;
          }
        }

        .content-chiffre {
          display: flex !important;
          // background-color: #2E6066;
          background: #35b6b1;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          padding-top: 3.5rem;
          padding-left: 1rem;
          padding-right: 1rem;
          padding-bottom: 3.5rem;

          .legende {
            opacity: 0;
            font-size: 1.2rem;
            color: white;
            width: 100%;
            text-align: center;
            font-size: calc(
              0.8rem + (26 - 14) * ((100vw - 300px) / (1600 - 300))
            );
            font-size: calc(
              1.1rem + (26 - 14) * ((100% - 100px) / (1600 - 300))
            );
            font-weight: 800;
          }

          .chiffre {
            opacity: 0;
            font-size: 4rem;
            color: white;
            line-height: 3.5rem;
            font-weight: 400;
            font-size: calc(
              2.1rem + (26 - 14) * ((100vw - 300px) / (1600 - 300))
            );
            font-size: calc(4rem + (26 - 14) * ((100% - 100px) / (1600 - 300)));
          }

          > * {
            height: auto !important;
          }
        }
      }
    }

    &.component-carrousel {
      padding: 0 !important;
      margin-top: 1rem !important;
      margin-bottom: 1rem !important;
      position: relative;
      //height: 235px;
      //height: 335px;

      .slick-slider {
        //height: 235px;
        //height: 335px;
        .slick-slide {
          //height: 235px;
          //height: 335px;
        }
        .component-image {
          //height: 235px;
          //height: 335px;
          img {
            height: 335px;
            border-radius: 5px;
          }
        }
      }

      .prev-slider,
      .next-slider {
        position: absolute;
        z-index: 1;
        top: 50%;
        background: #E20613;
        color: white;
        width: 2.2rem;
        height: 2.2rem;
        font-size: 0.9rem;
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }

      .prev-slider {
        left: 0;
        transform: translate(-50%, -120%);
      }

      .next-slider {
        right: 0;
        transform: translate(50%, -120%);
      }
    }

    &.component-image {
      width: 100% !important;
      padding: 0 !important;
    }

    &.component-breve {
      color: black;
      margin-top: 1.5rem;

      &.style {
        .inner {
          padding-left: 0.8rem !important;
          border-left: solid 10px;
        }

        @media (max-width: 425px) {
          //border: 3px solid;
          padding-top: 1.2rem;
          padding-bottom: 1.2rem;
        }
      }

      > .inner {
        > h2 {
          margin-top: 0;
        }
      }
    }

    &.component-encadre {
      border-radius: 3px;
      margin-top: 1.5rem;
      padding: 1.2rem;


      &.dark {
        q, p, h2, h3, figcaption, .author, .chiffre, .legende {
          color: #ffffff !important;
        }
      }

      .component-testimonial {
        border: none;
      }
    }
  }

  @import "./components-media.scss";
}
