//@media (max-width: 768px){

@media (min-height: 900px) and (max-width: 1025px) {
  .croix-rouge-v2 {
    .lateralBarView {
      width: 72px;

      &.menuShow {
        border-right: solid 1px rgba(0, 0, 0, 0.15);
      }

      button.menu-toggle-btn svg {
        width: 89px;
        transform: scale(0.75) translate(-27px, -4px) !important;
      }
    }
  }
}

@media (max-width: 425px) {
  .croix-rouge-v2 {
    .lateralBarView {
      position: fixed;
      left: 0;
      width: 100%;
      display: flex;
      height: auto;
      bottom: 0;
      flex-direction: row;
      padding-top: 0.7rem;
      padding-bottom: 0.7rem;
      justify-content: center;
      z-index: 10000000000;
      border-right: 0 !important;
      -webkit-transform: translate3d(0, 0, 0);

      .menu-search {
        display: block;
      }

      button.menu-numero-toggle-btn {
        margin-bottom: 0;
        order: 0;
        i {
          font-size: 1.5rem;
        }
      }
      .menu-search {
        order: 3;
        .searchBtn {
          i {
            font-size: 1.5rem;
          }
        }
      }
      button.menu-toggle-btn {
        order: 0;
        margin-left: 1.6rem;
        margin-right: 1.6rem;
        i {
          font-size: 1.5rem;
        }
      }

      .numero-lateral {
        display: none;
      }

      button.menu-toggle-btn {
        //height: 60px;
        //width: 60px;

        svg {
          width: 50px;
          height: 60px;
          transform: scale(1) translate(3px) !important;
        }
      }

      &.scrolled:not(.menuShow) {
        button.menu-toggle-btn {
          g {
            fill: black;
          }
        }
      }

      &.isSearching {
        button.menu-toggle-btn {
          g {
            fill: black;
          }
        }
      }

      &.menuShow {
        button.menu-toggle-btn {
          g {
            fill: white;
          }
        }
      }

      .menu {
        display: none;
      }

      .numero-info {
        display: none;
      }

      .numero-socials {
        display: none;
      }
    }
  }
}
