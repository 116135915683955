.croix-rouge-v2 {
  .main {
    position: relative;
    background: white;
    flex: 1;
    margin-left: 86px;
  }

  .warning {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .loading-numero {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background: white;
    z-index: 10000000;
  }

  .main {
    .loading-outer {
      width: 100%;
      height: 100vh;
      display: flex;
      justify-content: center;
      align-items: center;

      .loading {
      }
    }
  }

  @media (max-width: 425px) {
    .main {
      width: 100vw;
      margin-left: 0;
    }
  }
  @import "./main-media.scss";
}
