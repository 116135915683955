//@media (max-width: 768px){
@media (min-height: 900px) and (max-width: 1025px) {
  .main {
    margin-left: 72px;
    width: calc(100vw - 72px);
  }
}

@media (max-width: 425px) {
  .main {
  }
}
