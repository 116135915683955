@font-face {
  font-family: 'icomoon';
  src:  url('icomoon.eot?e9q9zg');
  src:  url('icomoon.eot?e9q9zg#iefix') format('embedded-opentype'),
    url('icomoon.ttf?e9q9zg') format('truetype'),
    url('icomoon.woff?e9q9zg') format('woff'),
    url('icomoon.svg?e9q9zg#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="ico-cr-"], [class*=" ico-cr-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ico-cr-chevron-down:before {
  content: "\e908";
}
.ico-cr-chevron-up:before {
  content: "\e91c";
}
.ico-cr-close:before {
  content: "\e91b";
}
.ico-cr-quote1:before {
  content: "\e91f";
}
.ico-cr-quote2:before {
  content: "\e920";
}
.ico-cr-arrow-agirc:before {
  content: "\e900";
}
.ico-cr-arrow-left-rounded:before {
  content: "\e901";
}
.ico-cr-arrow-left:before {
  content: "\e902";
}
.ico-cr-arrow-right-rounded:before {
  content: "\e903";
}
.ico-cr-arrow-right:before {
  content: "\e904";
}
.ico-cr-byridmi .path1:before {
  content: "\e905";
  color: rgb(0, 0, 0);
}
.ico-cr-byridmi .path2:before {
  content: "\e906";
  margin-left: -3.529296875em;
  color: rgb(0, 0, 0);
  opacity: 0.45;
}
.ico-cr-byridmi .path3:before {
  content: "\e907";
  margin-left: -3.529296875em;
  color: rgb(0, 0, 0);
  opacity: 0.45;
}
.ico-cr-facebook:before {
  content: "\e90a";
}
.ico-cr-instagram:before {
  content: "\e90b";
}
.ico-cr-kiosque:before {
  content: "\e90c";
}
.ico-cr-menu:before {
  content: "\e90d";
}
.ico-cr-next-article:before {
  content: "\e90e";
}
.ico-cr-prev-article:before {
  content: "\e90f";
}
.ico-cr-progress-dot:before {
  content: "\e910";
}
.ico-cr-progress:before {
  content: "\e911";
}
.ico-cr-read-circle:before {
  content: "\e912";
}
.ico-cr-read-public:before {
  content: "\e913";
}
.ico-cr-read:before {
  content: "\e914";
}
.ico-cr-uniE915:before {
  content: "\e915";
}
.ico-cr-uniE916:before {
  content: "\e916";
}
.ico-cr-search:before {
  content: "\e917";
}
.ico-cr-time:before {
  content: "\e918";
}
.ico-cr-triangle-intro:before {
  content: "\e919";
}
.ico-cr-twitter:before {
  content: "\e91a";
}
