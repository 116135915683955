//@media (max-width: 768px){
  @media (min-height: 900px) and (max-width: 1025px){

    .croix-rouge-v2 {
      .couverture {
  
        .frame {
          height: 79vh !important;
  
          .title {
            padding-top: 5%;
  
            img {
              //max-width: initial;
              width: initial;
              max-width: 50%;
            }
          }
  
        }
  
        .chapitres {
          height: 22vh;
  
          > div {
            overflow-x: auto;
            -webkit-overflow-scrolling: touch;
          }
  
          .chapitre {
            width: 75%;
            flex: 0 0 auto;
  
            a {
              justify-content: space-evenly;
              padding-bottom: 7%;
            }
  
            a .title {
              font-size: 1.24em;
              height: 2.48em;
              margin-bottom: 0rem;
            }
  
          }
  
        }
  
      }
  
      .article-couverture {
  
        > .inner {
          //padding-top: 9%;
          justify-content: center;
          //padding-bottom: 19%;
          padding: 7% 0 19% 7%;
          width: 100%;
  
          h2 {
            width: 91%;
          }
  
        }
  
      }
  
    }
  
  }
  
  @media (max-width: 425px) {
  
    .croix-rouge-v2 {
  
      .article-couverture {
        > .inner {
          position: absolute;
          display: flex;
          bottom: 3%;
          width: calc(100%);
          justify-content: flex-start;
          align-items: flex-start;
          flex-direction: column;
          color: white;
          z-index: 3;
          padding: 7%;
          padding-right: 7%;
          margin-bottom: 16%;
        }
      }
  
      .article-view {
  
        .time, .status {
          font-size: 1.1rem !important;
        }
        .start-btn {
          visibility: hidden;
        }
      }
  
      .couverture {
        .chapitres {
          .chapitre {
  
            a .time {
              //position: absolute;
              //bottom: 10px;
            }
  
            a .title {
              font-size: 1.24em;
              height: auto;
              margin-bottom: 3rem;
              margin-top: 0.5rem;
            }
  
          }
  
        }
  
      }
  
      .couverture {
  
        .chapitres {
          height: 22vh;
  
          > div {
            overflow-x: auto;
            -webkit-overflow-scrolling: touch;
          }
  
          .chapitre {
            width: 75%;
            flex: 0 0 auto;
  
            a {
              justify-content: space-evenly;
              padding: 5%;
              padding-bottom: 7%;
              padding-right: 5%;
  
              .status, .time {
                margin-top: 0.2em;
                font-size: 1.05rem;
                margin-left: 0.4rem;
              }
  
            }
  
            a .title {
              font-size: 1.24em;
              height: 2.48em;
              margin-bottom: 0rem;
            }
  
          }
  
        }
  
        .frame {
          height: 76% !important;
  
          .article-couverture {
  
            h2 {
              width: 90%;
            }
  
            .inner {
              padding: 8%;
              padding-top: 26%;
            }
  
          }
  
          .title {
            padding-left: 4%;
            padding-top: 4%;
            z-index: 10000000000000;
            pointer-events: none;
          }
  
        }
      }
    }
  
  
  }
  
  @media(max-width: 375px) {
  
    .croix-rouge-v2 {
      .couverture {
  
        .article-couverture h2, .article-couverture h1 {
          font-size: 2.49rem;
          line-height: 3rem;
        }
  
        .chapitres {
          height: 28vh;
  
          .chapitre {
  
            a .title {
              font-size: 1.2em;
              height: auto;
              line-height: 1.65rem;
              margin-bottom: 0;
              margin-top: 0;
              padding-left: 0.4rem;
            }
  
            a .time {
              //position: absolute;
              //bottom: 10px;
            }
  
            a .chapitre-titre {
              font-size: 1.02rem;
              line-height: 1.2rem;
              margin-bottom: 0.1rem;
              height: auto;
  
              .number {
                font-weight: 700;
                font-size: 1rem;
                border-radius: 100%;
                margin-right: 0.8rem;
                min-width: 1.6rem;
                min-height: 1.6rem;
                padding-left: 0.4rem;
                padding-top: 0.4rem;
              }
  
            }
  
          }
  
        }
  
      }
    }
  
  }
  