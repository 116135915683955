@import "../../mixins.scss";

.croix-rouge-v2 {
  .content {
    @media (max-width: 425px) {
      flex-direction: column;
    }
  }

  .global-content {
    width: 100%;

    > div {
      position: relative;
      display: flex;
      flex-direction: column;
      overflow: visible;

      &:after {
        @include wait(#f3f1f1);
      }
    }

    .citation {
      margin-top: 1.7rem;
      margin-bottom: 1.7rem;
      text-align: center;
      display: flex;
      justify-content: center;
      flex-direction: column;
      border-width: 2px;
      border-style: solid;
      border-radius: 5px;

      &.citation-picture {
        margin-top: 3.6rem;

        q {
          margin-top: -2rem;
        }
      }

      .picture {
        width: 100%;
        max-height: 8rem;
        transform: translate(0, -50%);

        img {
          object-fit: contain;
          max-width: 8rem;
          max-height: 8rem;
        }
      }
    }

    .testimonial {
      margin-top: 1.7rem;
      margin-bottom: 1.7rem;
      text-align: center;
      display: flex;
      justify-content: center;
      flex-direction: column;
      border-width: 2px;
      border-style: solid;
      border-radius: 5px;

      &.testimonial-picture {
        margin-top: 3.6rem;

        q {
          margin-top: -2rem;
        }
      }

      .picture {
        width: 100%;
        max-height: 8rem;
        transform: translate(0, -50%);

        img {
          object-fit: contain;
          max-width: 8rem;
          max-height: 8rem;
        }
      }
    }

    .component-text {
      overflow: hidden;
      //font-size: 0.95rem;
      font-size: 1rem;
      letter-spacing: 0.15px;
      margin-bottom: 1rem;

      &.style {
        padding-top: 1.5rem !important;
        padding-bottom: 1.5rem !important;
        margin-bottom: 2rem;
        margin-top: 2rem;
        h2,
        h3 {
          color: black;
          margin-bottom: 0.7rem;
          margin-top: 0.6rem;
        }
        p {
          color: black;
        }
      }

      p {
        overflow-wrap: break-word;
        margin-bottom: 0;
      }
    }

    .citation q {
      margin: 1.8rem;
      font-size: 1.1rem;
      line-height: 1.6rem;
      color: black;
      font-style: normal;
      display: block;

      @media (max-width: 425px) {
        font-size: 1.1rem;
        line-height: 1.6rem;
      }

      &:before {
        content: '';
      }

      &:after {
        content: '';
      }
    }

    .testimonial q {
      margin: 1.8rem;
      font-size: 1.1rem;
      line-height: 1.6rem;
      color: black;
      font-style: normal;
      display: block;

      @media (max-width: 425px) {
        font-size: 1.1rem;
        line-height: 1.6rem;
      }

      &:before {
        font-size: 1.2rem;
        display: inline-block;
        font-family: "icomoon";
        padding-right: 0.4rem;
        content: '\e91f';
        color: #E20613;
      }

      &:after {
        font-size: 1.2rem;
        display: inline-block;
        font-family: "icomoon";
        padding-left: 0.4rem;
        content: '\e920';
        color: #E20613;
      }
    }

    span.author {
      font-size: 0.9rem;
      font-weight: 600;
      line-height: 1.9rem;
      margin-top: -1.3rem;
      margin-bottom: 1.7rem;
      color: black;
    }

    h2 {
      font-size: 2.7rem;
      line-height: 3rem;
      margin-top: 1rem;
      font-weight: 400;
      margin-bottom: 1.1rem;
      color: black;
    }

    h3 {
      font-size: 2rem;
      line-height: 2rem;
      margin-top: 1.3rem;
      margin-bottom: 1.3rem;
      font-weight: 400;
      color: black;
    }

    p.style {
      border-left: solid 4px black;
      padding-left: 1.5rem;
      margin-bottom: 2rem;
      margin-top: 2rem;
    }

    .component-image {
      text-align: center;
      margin-top: 1rem;
      margin-bottom: 1.7rem;

      img {
        max-width: 100%;
        object-fit: cover;
        background: #f1f1f1;
        height: auto;
        width: auto;
        border-radius: 5px;
      }

      figcaption {
        margin-top: 0.8rem;
        color: #666666;
        font-size: 0.94rem;
      }
    }
  }
}
