@import "../../mixins.scss";

.croix-rouge-v2 {
  .couverture-legacy .content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 7%;
    color: white;
    z-index: -1;

  }

  .couverture-legacy {
    h1 {
      font-size: calc(1.7vw + 1vh + 1vmin);
      position: relative;

      a {
        &:after {
          @include wait(#c6c5c5);
        }
      }

    }
  }

  .couverture-legacy {
    .frame .title {
      position: absolute;
      top: 0;
      left: 0;
      padding-left: 2rem;
      padding-top: 1rem;
      z-index: 3;
      img {
        //max-width: 450px;
        max-width: 50%;
        //width: 100%;
      }

      a {
        color: white;

        &:hover {
          text-decoration: none;
        }

      }

    }
  }

  .couverture-legacy {

    .fx-img {
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      content: "";
      position: absolute;
      background: rgba(0, 0, 0, 0.25);
      z-index: 2;
    }

    .frame {
      position: relative;
      overflow: hidden;

      .imageView {
        width: 101%;
        height: 101%;
        will-change: transform;
        object-fit: cover;
        top: 0;
        left: 0;
        position: absolute;
  
        img {
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          position: absolute;
          object-fit: cover;
          will-change: opacity;
          opacity: 1;
        }
        //transition: all 0.40s cubic-bezier(0.53, -0.02, 0.21, 0.99) !important;
      }

      a:not(.start-btn) {
        color: white;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;

        &.read-more {
          position: relative;
          &:after {
            @include wait(#bab9b9);
          }
        }
      }

      p {
        display: flex;
        height: 0;
        overflow: hidden;
        transition: all 0.2s ease-out;
        transform: translateY(20px);
        opacity: 0;
        width: 70%;
        cursor: pointer;
      }

    }

  }

  .couverture-legacy {
    .no-img {
      .article-couverture {
        background: white;
      }
    }
  }

  .couverture-legacy {
  .article-couverture {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    //background: #ececec;
    background: #d1d1d1;

    .outer-i {
      margin-left: 1.2rem;
      display: flex;
    }

    .time {
      margin-right: 1.2rem;
    }

    .time, .status {
      //margin-top: 1em;
      //margin-top: 0.5em;
      font-size: 1rem;
      font-weight: 400;
      position: relative;
      display: flex;
      color: white;
      align-items: center;

      span {
        font-weight: 600;
        font-size: 0.9rem;
      }

      i {
        color: white;
        margin-right: 0.35rem;
      }

      @media( max-width: 425px) {
        font-size: 1.1rem;
        span {
          font-size: 1.1rem;
          line-height: 0.8rem;
        }
      }

    }

    .chapitre {
      font-size: 1.1rem;
      line-height: 1.1rem;
      color: white;
      // margin-bottom: 0.6rem;
      letter-spacing: 1px;
      display: flex;
      align-items: center;
      font-weight: 600 !important;
      position: relative;
      // background: #FB5058;
      padding: 0.2rem;
      padding-bottom: 0.8rem;
      padding-left: 0.3rem;

      .number {
        background: #f95e7d;
        font-weight: 600;
        font-size: 0.8rem;
        margin-right: 0.5em;
        padding: 0.2rem;
        height: 30px;
        width: 30px;
        border-radius:100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }

    }

    h1, h2 {
      font-size: 3.5rem;
      line-height: 3.9rem;
      font-weight: 800;
      width: 100%;
      transition: all 0.2s ease;
      cursor: pointer;
      transform-origin: top left;
      position: relative;
      display: inline;
      flex-direction: column;
      text-transform: uppercase;
      justify-content: flex-start;
      align-items: flex-start;
      color: white;
      padding-left: 12px;
      padding-right: 12px;

      @media(max-width: 425px){
        font-size: 3.4rem;
        line-height: 3.6rem;
      }

      &:hover {
        color: white;
      }


      span {
        padding-top: 0;
        padding-bottom: 0.2rem;
        line-height: 4.1rem;

        &:nth-child(1) {
          font-size: 3.2rem;
          line-height: 3rem;
          padding-top: 0.2rem;
        }
        &:nth-child(3) {
          padding-bottom: 0.7rem;
        }

      }

      @media( max-width: 320px) {
        font-size: 2rem;
        line-height: 2.3rem;
      }

      &:after {
        @include wait(#ececec);
      }

    }


    > .inner {
      position: absolute;
      display: flex;
      bottom: 0;
      //width: calc(100% / 3 * 2);
      // width: calc(80%);
      width: calc(100% / 3 * 2);
      justify-content: flex-start;
      align-items: flex-start;
      flex-direction: column;
      color: white;
      z-index: 3;
      padding: 7%;
      padding-right: 0%;
      padding-left: 5%;

      > a > div {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
      }

    }
  }

  .article-couverture {
    .outer-i {
      display: flex;
      z-index: 11;

      .time {
        margin-right: 0;
        padding-right: 0.85rem;
      }

      .status {
        padding-left: 0.85rem;
      }

      .time, .status {
        margin-top: 0;
        font-size: 0.8rem;

        i {
          color: white;

          &.icon-status_done {
            color: #4ea8b3;
          }
        }
      }

    }
  }



  .chapitres {
    height: 22vh;
    display: flex;

    > div {
      width: 100%;
      height: 100%;
      display: flex;
      flex-wrap: wrap;
    }

    .chapitre {
      display: flex;
      width: calc(100% / 3);
      height: 100%;
      border-right: solid 1px #D8D8D8;

      &:after {
        @include wait(#ececec);
        //transition: opacity 1s ease 0.5s, transform 0.1s 1s, -webkit-transform 0.1s 1s;
        //transition: opacity 1s ease 1s;
      }

      &:nth-child(4),  &:nth-child(5),  &:nth-child(6) {
        border-top: solid 1px #D8D8D8;
      }

      &:hover {
        background: #f3f3f3;
      }

      &:last-of-type {
        border-right: 0;
      }

      a {
        display: flex;
        flex-direction: column;
        color: black;
        padding: 7%;
        padding-right: 10%;
        //padding: 9%;
        padding-bottom: 0px;
        width: 100%;
        align-items: flex-start;

        @media(max-height: 700px) {
          padding-left: 14px;
          padding-top: 6px;
          padding-right: 15px;
          padding-bottom: 0;
        }

        &:hover {
          text-decoration: none;
        }

        .chapitre-titre {
          font-size: 0.9rem;
          line-height: 0.9rem;
          color: black;
          margin-bottom: 0.2rem;
          letter-spacing: 1px;
          display: flex;
          align-items: center;
          // height: 28px;
          font-weight: 600 !important;
          position: relative;
          padding: 0.2rem;
          padding-bottom: 0;

          &:after {
            @include wait(#eeeded)
          }

          .number {
            color: #fff;
            background:$red;
            padding: 0.2rem;
            padding-top: 5px;
            padding-right: 5px;
            transform: translateY(-1px);
            font-size: 0.8rem;
            font-weight: 900;
            border-radius: 100%;
            margin-right: 0.5rem;
            width: 1.1rem;
            padding-left: 0.27rem;
            height: 1.1rem;
            display: flex;
            justify-content: center;
            align-items: center;

          }
        }
        .title {
          font-size: 1.15rem;
          line-height: 1.4rem;
          min-height: 2.4rem;
          //margin-bottom: 0.5rem;
          margin-top: 0.4rem;
          position: relative;
          color: black;
          font-weight: 400;

          &:after {
            @include wait(#ececec)
          }

        }

        .outer-i {
          display: flex;
        }

        .time {
          margin-right: 0.8rem;
        }

        .status.lu i {
          color: #4ec575;
        }

        .time, .status {
          //margin-top: 1em;
          margin-top: 0.9em;
          font-size: 0.84rem;
          font-weight: 400;
          position: relative;
          display: flex;
          align-items: center;


          i {
            color: black;
            margin-right: 0.35rem;

            &.icon-status_done {
              color: lighten(black, 70%);
              &.no-done {
                color: #4EC575;
              }
            }

          }

          &:after {
            @include wait(#ececec)
          }

        }

      }

    }
  }
  }

  .couverture-legacy {
    .start-btn {
      position: absolute;
      bottom: 0;
      right: 0;
      width: calc(100% / 3);
      z-index: 10;

      > div {
        background-color: #E20613;
        width: 100%;
        box-sizing: border-box;
        background: $red;
        display: flex;
        padding: 1.6em;
        padding-top: 1.3rem;
        padding-bottom: 1.2rem;
        border-radius: 0;
        color: white;
        justify-content: center !important;
        align-items: center !important;
        font-weight: 400;
        font-size: 1.7rem;
        text-transform: uppercase;

        .inner-start {
          display: flex;
          justify-content: center;
          align-items: center;

          i {
            margin-left: 1rem;
            transform: translateY(0px);
            font-size: 1.2rem;
            transition: all 0.3s ease;
          }

        }

        &:hover {
          color: white;
          background: darken($red, 10%);

          i {
            margin-left: 1rem;
            transform: translateX(10px);
            font-size: 1.2rem;
            transition: all 0.6s ease;
          }

        }
      }

      &:after {
        @include wait(#d3d3d3);
      }

    }
  }
}

@import "./couverture-media.scss";
