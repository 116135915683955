//@media (max-width: 768px){
@media (min-height: 900px) and (max-width: 1025px) {
  .croix-rouge-v2 {
    .numero {
    }
  }
}

@media (min-height: 425px) {
  .croix-rouge-v2 {
    .numero {
      min-height: initial;
    }
  }
}
